import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material";
import theme from "../utils/theme";
import CssBaseline from "@mui/material/CssBaseline";

import { useSelector } from "react-redux";

import Home from "../pages/Home";
import NotFound from "../pages/404NotFound";

export default function Routes() {
  const ui = useSelector((state) => state.ui);
  const THEME = createTheme(theme(ui.isDarkMode));
  return (
    <ThemeProvider theme={THEME}>
      <CssBaseline />
      <Router>
        <Switch>
          <Route component={Home} path="/" exact />

          <Route component={NotFound} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
