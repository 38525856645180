const globalStyle = {
  headerPadding: {
    padding: {
      xs: "12px 16px",
      sm: "12px 32px",
      md: "18px 32px",
    },
  },
  sectionPadding: {
    padding: {
      xs: "68px 16px",
      sm: "68px 16px",
      md: "68px 16px",
    },
  },
};

export default globalStyle;
