import darkScrollbar from "@mui/material/darkScrollbar";

const theme = (isDarkMode) => ({
  palette: {
    mode: isDarkMode ? "dark" : "light", // change this to light later
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#E69215",
    },
    text: {
      primary: "#292929",
      t1: "#292929",
      t2: "#3B3B3B",
    },
    error: {
      main: "#F23F6A",
    },
    background: {
      default: "#FAFAFA",
    },
  },
  typography: {
    fontFamily: [
      "Helvetica",
      "Rubik",
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: "32px",
      fontWeight: "bold",
      lineHeight: "40px",
      fontStyle: "normal",
    },
    h2: {
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: "24px",
      fontStyle: "normal",
    },
    h3: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "20px",
      fontStyle: "normal",
    },
    h4: {
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "20px",
      fontStyle: "normal",
    },
    h5: {
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: "20px",
      fontStyle: "normal",
    },
    sh: {
      fontSize: "12px",
      fontWeight: "bold",
      lineHeight: "16px",
      fontStyle: "normal",
      display: "block",
    },
    p: {
      fontSize: "12px",
      fontWeight: "normal",
      lineHeight: "16px",
      fontStyle: "normal",
      display: "block",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: isDarkMode ? darkScrollbar() : null, // TODO: body: isDarkMode ? darkScrollbar() : null,
      },
    },
  },
});

export default theme;
