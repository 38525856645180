import * as actionTypes from "../types";

const inititalState = {
  isDarkMode: false,
};

function uiReducer(state = inititalState, action) {
  switch (action.type) {
    case actionTypes.SET_THEME:
      return {
        ...state,
        isDarkMode: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}

export default uiReducer;
