import React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Link,
} from "@mui/material";

import LogoImage from "../assets/img/logo.png";
import { ReactComponent as DexToolsIcon } from "../assets/svg/dextools.svg";
import { ReactComponent as DiscordIcon } from "../assets/svg/discord-brands.svg";
import { ReactComponent as EtherscanIcon } from "../assets/svg/etherscan-logo-circle.svg";
import { ReactComponent as InstagramIcon } from "../assets/svg/instagram-brands.svg";
import { ReactComponent as RedditIcon } from "../assets/svg/reddit-brands.svg";
import { ReactComponent as TelegramIcon } from "../assets/svg/telegram-brands.svg";
import { ReactComponent as TwitterIcon } from "../assets/svg/twitter-brands.svg";
import { ReactComponent as UniswapIcon } from "../assets/svg/uniswap-uni-logo.svg";

const style = {
  logoContainer: {
    display: "flex",
    alignItems: "center",
  },
  logo1: {
    height: 120,
  },
  text1: {
    textTransform: "uppercase",
    color: (theme) => theme.palette.text.primary,
  },
  socialIconContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  socialIcon: {
    height: 40,
    width: 40,
  },
};
export default function Header() {
  return (
    <Box component="header">
      <AppBar position="static">
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Link
            sx={style.logoContainer}
            underline="none"
            href="https://www.schrodingertoken.com/"
          >
            <Box component="img" src={LogoImage} alt="logo" sx={style.logo1} />
            <Typography variant="h1" sx={style.text1}>
              Super Dinger
            </Typography>
          </Link>
          <Box component="span" sx={{ flexGrow: 1 }} />
          <Box sx={style.socialIconContainer}>
            <IconButton
              size="large"
              component={Link}
              href="https://t.me/dingertoken"
              target="_blank"
              rel="noopener"
            >
              <TelegramIcon style={{ ...style.socialIcon, color: "#2BA0D9" }} />
            </IconButton>
            <IconButton
              size="large"
              href="https://twitter.com/dingertoken"
              target="_blank"
              rel="noopener"
            >
              <TwitterIcon style={{ ...style.socialIcon, color: "#1C9CEA" }} />
            </IconButton>
            <IconButton
              size="large"
              href="https://www.instagram.com/schrodingertoken/"
              target="_blank"
              rel="noopener"
            >
              <InstagramIcon
                style={{ ...style.socialIcon, color: "#DF3F76" }}
              />
            </IconButton>
            <IconButton
              size="large"
              href="https://www.reddit.com/r/schrodinger/"
              target="_blank"
              rel="noopener"
            >
              <RedditIcon style={{ ...style.socialIcon, color: "#F43908" }} />
            </IconButton>
            <IconButton
              size="large"
              href="https://discord.com/invite/u426hmda5T"
              target="_blank"
              rel="noopener"
            >
              <DiscordIcon style={{ ...style.socialIcon, color: "#5562EA" }} />
            </IconButton>
            <IconButton
              size="large"
              href="https://www.dextools.io/app/ether/pair-explorer/0x21a7afa9f7e2d48bfa8e8f384d77f3b5c19a5add"
              target="_blank"
              rel="noopener"
            >
              <DexToolsIcon style={style.socialIcon} />
            </IconButton>
            <IconButton
              size="large"
              href="https://etherscan.io/token/0x9e5bd9d9fad182ff0a93ba8085b664bcab00fa68"
              target="_blank"
              rel="noopener"
            >
              <EtherscanIcon style={style.socialIcon} />
            </IconButton>
            <IconButton
              size="large"
              href="https://app.uniswap.org/#/swap?outputCurrency=0x9e5bd9d9fad182ff0a93ba8085b664bcab00fa68"
              target="_blank"
              rel="noopener"
            >
              <UniswapIcon style={style.socialIcon} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
